<template>
  <div class="page-coantainer">
    <el-dialog
      title="招生老师"
      :visible.sync="updateData.isOpen"
      width="40%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="ID" prop="name">
            <el-input :value="updateData.id" disabled></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model.trim="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="手机" prop="phone">
            <el-input v-model.trim="formData.phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitUpdatePromoter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPromoterInfoById, updatePromoter } from '@/api/api.js'
export default {
  props: {
    updateData: {
      type: Object
    }
  },
  data () {
    return {
      isOpen: false,
      formData: {
        name: '',
        phone: '',
        qrCode: ''
      },
      schoolList: [],
      rules: {
        name: [
          { required: true, message: '请填写院校', trigger: 'blur' },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur'
          }
        ]
        // phone: [
        //   { required: true, message: '请填写手机号码', trigger: 'blur' },
        //   { min: 11, max: 11, message: '长度为11个字符', trigger: 'blur' }
        // ]
      }
    }
  },
  watch: {
    'updateData.isOpen' (data) {
      if (data) {
        this.getPromoterInfoById(this.updateData.id)
      }
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.formData.qrCode = this.$base_url + '/upload/image/' + res.data.fileName
    },
    /* 上传二维码 */
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    /* 更新推广员 */
    submitUpdatePromoter () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          updatePromoter({
            id: this.updateData.id,
            name: this.formData.name,
            phone: this.formData.phone,
            qrCode: this.formData.qrCode
          }).then((res) => {
            this.$emit('closeUpdateDialog', { isOpen: false, type: 'success' })
            this.$refs.form.resetFields()
            this.$message({
              message: '更新成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 根据id获取推广员信息 */
    getPromoterInfoById (id) {
      getPromoterInfoById(id).then((res) => {
        this.formData = res.data
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.isOpen = false
      this.$emit('closeUpdateDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
    .avatar-uploader {
      text-align: center;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      text-align: center;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}
</style>
